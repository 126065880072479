<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:34:47
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 20:48:10
 * @Description:
-->
<template>
  <Tab :list="tabList" :current="0" :showLine="true" />
  <Table class="flex-1" :needScroll="false" :tableConfig="tableConfig">
    <template v-slot:param3="scope">
      {{ scope.row.param3 ? scope.row.param3 + '分' : '-' }}
    </template>
    <template v-slot:param7="scope">
      <span class="link" @click="() => showDetail(scope.row)">查看</span>
      <span class="link" style="margin-left: 10px;color: blue;" @click="() => goTo(scope.row)">冷链溯源</span>
    </template>
  </Table>
  <van-pagination
    v-model="currentPage"
    class="fff"
    @change="handleCurrentChange"
    :total-items="allData.length"
    :items-per-page="10"
    mode="simple"
  >
    <template #prev-text>
      <van-icon name="arrow-left" />
    </template>
    <template #next-text>
      <van-icon name="arrow" />
    </template>
  </van-pagination>
</template>

<script>
import Tab from '@/components/tab/index.vue'
import Table from '@/components/table/index.vue'
import { reactive, ref } from 'vue'
import { getList } from '@/api/traceability'
import { traceabilityTabList } from '@/utils/tab'
import { useRouter } from 'vue-router'

export default {
  components: {
    Tab,
    Table,
  },
  setup() {
    // 表格相关的
    let currentPage = ref(1)
    let allData = ref([]);
    const tableConfig = reactive({
      params: {
        stallName: {
          label: '摊位号',
          align: 'center',
          width: '15%',
        },
        userName: {
          label: '经营户',
          align: 'center',
          width: '15%',
        },
        stallScopeName: {
          label: '主营项目',
          align: 'center',
          width: '30%',
        },
        creditRank: {
          label: '信用评分',
          align: 'center',
          width: '15%',
        },
        param7: {
          label: '更多信息',
          align: 'center',
          width: '25%',
          valueType: 'slot',
        },
      },
      data: [],
    })
    getList().then((res) => {
      allData.value = res.result
      handleCurrentChange(1)
    })

    const router = useRouter()
    const showDetail = (row) => {
      router.push({
        path: '/traceability/detail',
        query: {
          id: row.stallId,
        },
      })
    }
    const goTo = (row) => {
      router.push({
        path: '/traceability/cold-chain',
        query: {
          id: row.stallId,
        },
      })
    }

    function paginate(array, page_size, page_number) {
      return allData.value.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const  handleCurrentChange = (val) => {
      console.log(`当前页: ${val}`);
      tableConfig.data = paginate(allData,10,val);
    }

    const tabList = reactive(traceabilityTabList)
    return {
      tabList,
      tableConfig,
      showDetail,
      goTo,
      handleCurrentChange,
      currentPage,
      allData
    }
  },

}
</script>

<style lang="less" scoped>
.link {
  color: #49c5a5;
}
</style>
